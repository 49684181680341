<template>
  <div>
    <div class="card">
      <h6 class="card-header" v-if="!toggle">
        {{topic.code}}
        <span v-if="method === 'edit'">
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
        </span>
      </h6>
      <div class="card-body pb-0" v-if="!toggle">
        <div class="card-text">
          <div class="row">
            <dd class="col-4 col-sm-3">ชื่อเอกสาร:</dd>
            <dd class="col-8 col-sm-9">{{topic.name}}</dd>

            <dd class="col-4 col-sm-3">ฉบับครั้งที่:</dd>
            <dd class="col-8 col-sm-9">{{topic.revision}}</dd>

            <dd class="col-4 col-sm-3">วันที่มีผล:</dd>
            <dd class="col-8 col-sm-9">{{topic.effectiveDate | date}}</dd>

            <dd class="col-4 col-sm-3">เหตุผลการเปลี่ยนแปลง:</dd>
            <dd class="col-8 col-sm-9">{{topic.content}}</dd>
          </div>
        </div>
      </div>
      <div class="card-header" v-if="toggle">
        <h6 class="card-title">
          หมายเลขเอกสาร:
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
        </h6>

        <div class="form-row">
          <sgv-input-text
            placeholder="หมายเลขเอกสาร..."
            v-model="formData.code"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.code.required && $v.formData.$dirty}
            ]">
          </sgv-input-text>

          <sgv-input-text
            label="ชื่อเอกสาร"
            placeholder="ชื่อเอกสาร..."
            v-model="formData.name"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.name.required && $v.formData.$dirty}
            ]">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <sgv-input-number
            label="ฉบับครั้งที่"
            v-model="formData.revision"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.revision.required && $v.formData.$dirty}
            ]">
          </sgv-input-number>

          <sgv-input-date
            label="วันที่มีผล"
            v-model="formData.effectiveDate"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.effectiveDate.required && $v.formData.$dirty}
            ]">
          </sgv-input-date>
        </div>

        <div class="form-row">
          <sgv-input-textarea
            label="เหตุผลการเปลี่ยนแปลง"
            v-model="formData.content"
            class="col-12"
            :rows="3"
            auto-grow
            :validations="[
              {text: 'required!', value: !$v.formData.content.required && $v.formData.$dirty}
            ]">
          </sgv-input-textarea>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateTopic(topic.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyTopic(topic.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_TOPIC,
  DESTROY_TOPIC,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        code: '',
        name: '',
        revision: 0,
        effectiveDate: '',
        content: ''
      },
      actionOptions: [
        {text: 'New Issue ออกเอกสารใหม่', value: 'create'},
        {text: 'Revision แก้ไขเอกสาร', value: 'revision'},
        {text: 'Cancelled ยกเลิกเอกสาร', value: 'cancelled'},
        {text: 'Missing เอกสารสูญหาย', value: 'missing'},
      ],
    }
  },
  validations: {
    formData: {
      code: {required},
      name: {required},
      revision: {required},
      effectiveDate: {required},
      content: {required},
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    updateTopic (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    topic: {
      handler (v) {
        this.formData.code = v.code
        this.formData.name = v.name
        this.formData.revision = v.revision
        this.formData.effectiveDate = v.effectiveDate
        this.formData.content = v.content
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">

</style>
