var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[(!_vm.toggle)?_c('h6',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.topic.code)+" "),(_vm.method === 'edit')?_c('span',[_c('fa',{staticClass:"text-warning mx-2 pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}})],1):_vm._e()]):_vm._e(),(!_vm.toggle)?_c('div',{staticClass:"card-body pb-0"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"row"},[_c('dd',{staticClass:"col-4 col-sm-3"},[_vm._v("ชื่อเอกสาร:")]),_c('dd',{staticClass:"col-8 col-sm-9"},[_vm._v(_vm._s(_vm.topic.name))]),_c('dd',{staticClass:"col-4 col-sm-3"},[_vm._v("ฉบับครั้งที่:")]),_c('dd',{staticClass:"col-8 col-sm-9"},[_vm._v(_vm._s(_vm.topic.revision))]),_c('dd',{staticClass:"col-4 col-sm-3"},[_vm._v("วันที่มีผล:")]),_c('dd',{staticClass:"col-8 col-sm-9"},[_vm._v(_vm._s(_vm._f("date")(_vm.topic.effectiveDate)))]),_c('dd',{staticClass:"col-4 col-sm-3"},[_vm._v("เหตุผลการเปลี่ยนแปลง:")]),_c('dd',{staticClass:"col-8 col-sm-9"},[_vm._v(_vm._s(_vm.topic.content))])])])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-header"},[_c('h6',{staticClass:"card-title"},[_vm._v(" หมายเลขเอกสาร: "),_c('fa',{staticClass:"text-warning mx-2 pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"placeholder":"หมายเลขเอกสาร...","validations":[
            {text: 'required!', value: !_vm.$v.formData.code.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"ชื่อเอกสาร","placeholder":"ชื่อเอกสาร...","validations":[
            {text: 'required!', value: !_vm.$v.formData.name.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-number',{staticClass:"col-12",attrs:{"label":"ฉบับครั้งที่","validations":[
            {text: 'required!', value: !_vm.$v.formData.revision.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.revision),callback:function ($$v) {_vm.$set(_vm.formData, "revision", $$v)},expression:"formData.revision"}}),_c('sgv-input-date',{staticClass:"col-12",attrs:{"label":"วันที่มีผล","validations":[
            {text: 'required!', value: !_vm.$v.formData.effectiveDate.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.effectiveDate),callback:function ($$v) {_vm.$set(_vm.formData, "effectiveDate", $$v)},expression:"formData.effectiveDate"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"เหตุผลการเปลี่ยนแปลง","rows":3,"auto-grow":"","validations":[
            {text: 'required!', value: !_vm.$v.formData.content.required && _vm.$v.formData.$dirty}
          ]},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateTopic(_vm.topic.id)}}},[_vm._v(" แก้ไข ")]),_c('button',{staticClass:"btn btn-danger float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyTopic(_vm.topic.id)}}},[_vm._v(" ลบ ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }